import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Icon from "components/Icon";
import IconHeartOutline from "assets/icons/heartOutline.svg";
import IconHeartFill from "assets/icons/heartFill.svg";
import { findAmen, addLSAmen, removeLSAmen } from "services/authService";
import { getAmen, getAmenGroup } from "services/services";

export function AmenButtonStyled(props) {
  const { t } = useTranslation();
  const [fullHeart, setFullHeart] = useState(false);
  const [totalAmen, setTotalAmen] = useState(0);

  const {
    index = 0,
    owner,
    ownerType,
    ownerGroup,
    handleAmen,
    handleUnAmen,
    testid,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    if (owner) fetchAmen(owner, ownerGroup);
  }, [owner]);

  const fetchAmen = async (id, ownerGroup) => {
    let amen;
    if (ownerGroup !== undefined) {
      amen = await getAmenGroup(id, ownerType, ownerGroup);
    } else {
      amen = await getAmen(id, ownerType);
    }
    if (amen?.mine) addLSAmen(id, ownerType);
    setTotalAmen(amen?.total || 0);
    setFullHeart(amenCheck(id, ownerType));
  };

  const amenCheck = (id, code) => {
    return !!findAmen(id, code);
  };

  const contentFavoriteIcon = (isFavorite, inx) => {
    const id = isFavorite
      ? `ico_heart__fill_${inx}`
      : `ico_heart_outline__${inx}`;
    const icon = isFavorite ? IconHeartFill : IconHeartOutline;
    const alt = "icon favorite";
    return contentDynamicIcon(id, icon, alt);
  };

  const contentDynamicIcon = (id, icon, alt) => {
    return <Icon id={id} data-testid={id} icon={icon} alt={alt} />;
  };

  const countAmen = (up = 0) => {
    let amens = totalAmen + (up ? Number(up) : -1);
    amens = amens <= 0 ? 0 : amens;
    setTotalAmen(amens);
  };

  return (
    <Button
      css={classes.rootBtnAmen}
      onClick={() => {
        countAmen(!fullHeart);
        setFullHeart(!fullHeart);
        if (!fullHeart) {
          addLSAmen(owner, ownerType);
          handleAmen();
        } else {
          removeLSAmen(owner, ownerType);
          handleUnAmen();
        }
      }}
      id={testid}
      data-testid={testid}
      type="tertiary"
      iconLeft={contentFavoriteIcon(fullHeart, index)}
      // text={`${totalAmen} ${t("components.button.Amen")}`}
      text={`${t("components.button.Amen")}`}
    />
  );
}
