import React from "react";
import { useStyles } from "./styles";
import { Grid, Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const DonateView = (props) => {
  const { isMd, isXs } = props;
  const { t } = useTranslation();
  const classes = useStyles({ mediaQueryMd: isMd, mediaQueryXs: isXs });

  return (
    <div data-testid="donate_tab_view" className={classes.root}>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <h1 data-testid="txt_donate_tab_title">
              {t("pages.donate.title")}
            </h1>
            <p data-testid="txt_donate_tab_description">
              {t("pages.donate.description")}
            </p>
            <Container maxWidth="md" className={classes.formContainer}>
              <div
                className={classes.iframeWrapper}
                data-testid="content_donate_tab_form_container"
              >
                <script
                  src="https://donorbox.org/widget.js"
                  paypalexpress="false"
                ></script>
                <iframe
                  data-testid="iframe_donate_tab_form"
                  src={t("pages.donate.iframe_src")}
                  width="100%"
                  style={{
                    maxWidth: 1170,
                    minWidth: 288,
                    maxHeight: "none !important",
                  }}
                  seamless="seamless"
                  name="donorbox"
                  frameBorder="0"
                  scrolling="no"
                  allowpaymentrequest="true"
                ></iframe>
                <div
                  className={classes.hackColor}
                  data-testid="content_donate_tab_color"
                ></div>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DonateView;
