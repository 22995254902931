import makeStyles from "@material-ui/core/styles/makeStyles";
export const useStyles = makeStyles({
  root: ({ mediaQueryMd }) => {
    const style = {
      backgroundColor: "#f4f6f8",
      paddingTop: "10em",
      paddingBottom: "1.5em",
      color: "#444c67",
      "& h1": {
        textAlign: "center",
        marginBottom: 16,
        width: "100%",
        fontSize: 32,
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
      },
      "& p": {
        margin: 0,
        marginBottom: "1em",
        fontSize: 18,
        fontFamily: "Nunito Sans",
        fontWeight: 600,
      },
      "& p, & h1, & div": {
        marginLeft: "auto",
        marginRight: "auto",
      },
    };
    if (mediaQueryMd) {
      style.paddingTop = "7em";
    }
    return style;
  },
  formContainer: {
    marginTop: "1rem",
    marginBottom: "1.3rem",
  },
  iframeWrapper: () => ({
    width: "100%",
    height: "1300px",
    border: "none",
    overflow: "hidden",
    position: "relative",
    transition: "height 0.3s ease-in-out",
    "& iframe": {
      width: "100%",
      height: "1300px",
      border: "none",
      overflow: "hidden",
      position: "relative",
      zIndex: 1
    }
  }),
  hackColor: {
    position: "absolute",
    height: "calc(100% - 200px)",
    width: "100%",
    top: 0,
    pointerEvents: "none",
    mixBlendMode: "lighten",
    backgroundColor: "#E7C26D",
    zIndex: 2
  },
});
